import React from 'react';
import { Nav } from '../components/Navbar';

const Experience = () => {
    return (
        <>
            <Nav />
        </>
    );
}

export default Experience;